import React, { Component } from 'react';
import { Link } from 'gatsby';
// import UserLinks from "../UserLinks/UserLinks";
import "./Nav.scss";

class Nav extends Component {
  render() {
    return (
      <nav className="nav">
        <ul className="nav__list">
          <li className="nav__list__item">
            <Link className="nav__link" to="/">🎈</Link>
          </li>
          <li className="nav__list__item">
            <Link className="nav__link" to="/about">About</Link>
          </li>
        </ul>
        <ul className="nav__list">
          {/* <li className="nav__list__item">
            <Link className="nav__link" to="/projects">Projects</Link>
          </li>
          <li className="nav__list__item">
            <Link className="nav__link" to="/teaching">Teaching</Link>
          </li> */}
          <li className="nav__list__item">
            <Link className="nav__link" to="/articles">Articles</Link>
          </li>
          <li className="nav__list__item">
            <Link className="nav__link" to="/cv">Vitae</Link>
          </li>
          {/* <li className="nav__list__item">
            <Link className="nav__link" to="/notes">Notes</Link>
          </li> */}
        </ul>
      </nav>
    );
  }
}

export default Nav;
